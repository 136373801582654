import { useEffect } from "react";
import { Box, Typography } from "@mui/material";

import { useNetworkStore } from "@core";
import IndoorClimatePage from "@components/IndoorClimate/IndoorClimatePage";
import { SectionSelector } from "@shared";

import { usePropertyStore } from "../../stores/property.store";
import { PropertyDetailEnergyData } from "./PropertyDetail.energy";
import { PropertyDetailMaintenance } from "./PropertyDetail.maintenance";
import { PropertyDetailOptimization } from "./PropertyDetail.optimization";
import { PropertyDetailOverview } from "./PropertyDetail.overview";

function UnderConstruction() {
  return (
    <Box pt={5} display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h3">Under Construction</Typography>
    </Box>
  );
}

export function PropertyDetailModule() {
  const propertyId = useNetworkStore((s) => s.selectedSubstationId);
  const fetch = usePropertyStore((s) => s.fetch);

  useEffect(() => {
    if (!propertyId) return;
    void fetch(propertyId);
  }, [propertyId, fetch]);

  return (
    <SectionSelector
      sections={[
        { title: "overview", id: "overview", component: PropertyDetailOverview },
        { title: "insights", id: "insights", component: UnderConstruction, disabled: true },
        {
          title: "indoor climate",
          id: "indoor-climate",
          component: IndoorClimatePage,
        },
        {
          title: "energy consumption",
          id: "energy_consumption",
          component: PropertyDetailEnergyData,
        },
        {
          title: "optimization",
          id: "optimization",
          component: PropertyDetailOptimization,
          disabled: true,
        },
        {
          title: "maintenance",
          id: "maintenance",
          component: PropertyDetailMaintenance,
          disabled: true,
        },
      ]}
    />
  );
}
