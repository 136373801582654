/* eslint-disable @typescript-eslint/no-explicit-any */
import { queryOptions } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { create, type StateCreator } from "zustand";

import { ResourceApi } from "@apis/resource.api";
import { COLUMN_PERIOD, R12 } from "@config";
import { logger as baseLogger, queryClient, useNetworkStore } from "@core";
import { zustandMiddlware } from "@core/stores/middleware";
import type { ColumnMeta } from "@core/types/common";
import { createInfoBlockGridColumns } from "@core/utils/columns/createInfoBlockGridColumns";
import { generateColumnsBySBT } from "@core/utils/columns/utils";
import type { MonthOption } from "@shared/ui/analytics/tables/InfoBlockGrid/const";

const logger = baseLogger.getSubLogger({ name: "dashboard.store" });

const defaultColumnIds = [
  "id",
  "customer.name",
  "install_address.street",
  "distribution.grid_area",
  `core.heat_energy_sum`,
  `core.volume_sum`,
  `core.returntemp_flowweighted_avg`,
];

const availableBlocks = [
  "building",
  "customer",
  "distribution",
  "install_address",
  "pricing",
  "installation",
];

type DataRow = {
  [key: string]: any;
  sub_id: string;
};

export type ColumnPeriod = (typeof COLUMN_PERIOD)[keyof typeof COLUMN_PERIOD];

type State = {
  networksFetched: boolean;
  substationsFetched: boolean;
  ready: boolean;
  networkData: any;
  substationData: any[] | null;
  error: string | null;
  rows: DataRow[];
  periodArgs: { year: number; month: number; isR12: boolean };
  activePeriod: ColumnPeriod;
  month: MonthOption;
  showSubstationDetails: boolean;
  sensorsData: any[] | null;
  sensorsFetched: boolean;
  fetchingSensor: boolean;
};

type Action = {
  setError: (error: string | null) => void;
  setActivePeriod: (activePeriod: ColumnPeriod) => void;
  setMonth: (month: MonthOption) => void;
  setShowSubstationDetails: (showSubstationDetails: boolean) => void;
};

type DashboardStore = State & Action;

const dashboardStore: StateCreator<
  DashboardStore,
  [["zustand/devtools", never]],
  [],
  DashboardStore
> = (set, get) => ({
  // Initial State
  networksFetched: false,
  substationsFetched: false,
  ready: false,
  networkData: null,
  substationData: null,
  error: null,
  rows: [],
  activePeriod: COLUMN_PERIOD.year,
  month: null,
  showSubstationDetails: false,
  sensorsData: null,
  sensorsFetched: false,
  fetchingSensor: false,

  // Actions
  setError: (error) => set({ error }),
  setMonth: (month) => set({ month }),
  setActivePeriod: (activePeriod) => set({ activePeriod }),
  setShowSubstationDetails: (showSubstationDetails) => set({ showSubstationDetails }),

  get periodArgs() {
    return {
      year: useNetworkStore.getState().activeYear || 2023,
      month: 0,
      isR12: false,
    };
  },

  get yearOptions() {
    const year = useNetworkStore.getState().lpYear?.year;
    if (!year) return [];

    const options = [];
    for (let i = 0; i < 8; i++) {
      options.push(DateTime.fromObject({ year }).plus({ years: -1 * i }).year);
    }
    options.push(R12);
    return options;
  },
});

export default create<DashboardStore>()(
  zustandMiddlware(dashboardStore, {
    name: "dashboardStore",
  })
);
