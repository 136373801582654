import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SearchOutlined } from "@mui/icons-material";
import { Button, CircularProgress, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useStore } from "zustand";

import { getSensorsPerSubstationOptions } from "@apis/resource.queries";
import { useNetworkStore } from "@core";
import { ControlPanel } from "@components/EnergyConsumption";
import { Resolution } from "@components/EnergyConsumption/ControlPanel";
import apiStore from "@stores/api.store";

import ControlPanelItem from "./ControlPanelItem";
import IndoorClimateGraph from "./IndoorClimateGraph";
import SensorCheckbox, { Sensor } from "./SensorCheckbox";

const averageSensorItem = (sensors: Sensor[]) => ({
  uid: "average-sensor",
  external_id: `Average temperature (${sensors.length} sensors)`,
  sensor_type_technical_name: "average_sensor",
});

const IndoorClimatePage = () => {
  // Client State
  const selectedSubstationId = useStore(useNetworkStore, (s) => s.selectedSubstationId);

  const { t } = useTranslation(["_action"]);
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const [resolution, setResolution] = useState<Resolution>("hourly");
  const [dateRange, setDateRange] = useState({
    startDate: null as DateTime | null,
    endDate: null as DateTime | null,
  });
  const [selectedSensors, setSelectedSensors] = useState<string[]>([]); // Track selected sensors by UID

  // Server State
  const { data: sensorsData, isFetching: fetchingSensor } = useQuery(
    getSensorsPerSubstationOptions(apiStore.get.apis(), selectedSubstationId)
  );

  const sensorList = sensorsData ? [averageSensorItem(sensorsData), ...sensorsData] : [];

  const handlePanelToggle = (isOpen: boolean) => {
    setIsPanelOpen(isOpen);
  };

  const handleSelectedAll = () => {
    const allSensors = sensorsData || [];
    const allSelectedUids = [
      averageSensorItem(allSensors).uid,
      ...allSensors.map((sensor) => sensor.uid),
    ];
    setSelectedSensors(allSelectedUids);
  };

  const handleDeselectAll = () => {
    setSelectedSensors([]);
  };

  const handleResolutionChange = (newResolution: Resolution) => {
    setResolution(newResolution);
  };

  const handleDateRangeChange = (startDate: DateTime | null, endDate: DateTime | null) => {
    setDateRange({ startDate, endDate });
  };

  return (
    <Box width="100%">
      <Box sx={{ display: "flex", width: "100%" }}>
        {/* Control Panel Sidebar */}
        <Box
          sx={{
            position: "sticky",
            top: 0,
            height: `calc(100vh - 200px)`,
            flexShrink: 0,
            zIndex: 10,
            alignSelf: "flex-start",
            bgcolor: "background.paper",
          }}
        >
          <ControlPanel
            isOpen={isPanelOpen}
            onToggle={handlePanelToggle}
            onResolutionChange={handleResolutionChange}
            onDateRangeChange={handleDateRangeChange}
          >
            {/* Sensor content */}
            {fetchingSensor ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minHeight: "100px",
                }}
              >
                <CircularProgress size={32} />
              </Box>
            ) : (
              <ControlPanelItem title="Sensor Display">
                <Box py={2} display="flex" justifyContent="space-between">
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: "20px", textTransform: "none" }}
                    onClick={handleSelectedAll}
                    disabled={!!(selectedSensors.length === sensorList.length)}
                  >
                    {t("action_select_all")}
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: "20px", textTransform: "none" }}
                    disabled={!selectedSensors.length}
                    onClick={handleDeselectAll}
                  >
                    {t("action_deselect_all")}
                  </Button>

                  {/*TODO: Add functionality */}
                  <SearchOutlined />
                </Box>
                {/* Sensor checkbox list */}
                <Box sx={{ maxHeight: 200, overflow: "auto", scrollbarWidth: "thin" }}>
                  {sensorList?.map((sensor) => (
                    <SensorCheckbox
                      key={sensor.uid}
                      sensor={sensor}
                      selectedSensors={selectedSensors}
                      setSelectedSensors={setSelectedSensors}
                    />
                  ))}
                </Box>
              </ControlPanelItem>
            )}
          </ControlPanel>
        </Box>
        {/* Graph Content */}
        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
            paddingLeft: "16px",
            transition: "padding 0.3s ease",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} mb={2}>
              {/* Indoor Climate Graph */}
              {selectedSensors.length > 0 && (
                <IndoorClimateGraph
                  selectedSensors={selectedSensors}
                  resolution={resolution}
                  dateRange={dateRange}
                />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default IndoorClimatePage;
