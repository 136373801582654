/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance } from "axios";

import { logger as baseLogger } from "@core/logger";
import { Network } from "@core/models";
import useAuthStore from "@core/stores/auth.store";

import { customAxiosDefaults } from "./apiClient";

const logger = baseLogger.getSubLogger({ name: "resource.api" });

type MdslResponse = {
  data:
    | {
        collection: any[];
      }
    | any;
  error?: {
    message: string;
  };
};

export class ResourceApi {
  client: AxiosInstance;
  constructor(client: AxiosInstance) {
    logger.debug("ResourceApi constructor");
    this.client = client;
  }

  async getNetworks(): Promise<Network[]> {
    return this.client.get("/v1/networks").then((response: MdslResponse) => {
      return response.data.data.collection;
    });
  }

  async getSubstations(): Promise<Network[]> {
    return this.client.get("/v1/substations").then((response: MdslResponse) => {
      return response.data.data.collection;
    });
  }

  async getSensorsBySubstation(substationUid?: string): Promise<any[]> {
    return this.client
      .get(`/v1/substations/${substationUid}/sensors`)
      .then((response: MdslResponse) => {
        return response.data.data?.flex_stations[0]?.sensors;
      });
  }

  async getMeterData(
    substationUID: string,
    components: string[] = ["heat_energy", "returntemp", "supplytemp"],
    fromDate?: string,
    toDate?: string,
    pageSize?: number,
    page?: number
  ): Promise<any> {
    const params = new URLSearchParams();

    // Add components parameter
    if (components.length > 0) {
      params.append("component[in]", components.join(","));
    }

    // Add date range parameters if provided
    if (fromDate) {
      params.append("datetime[ge]", fromDate);
    }

    if (toDate) {
      params.append("datetime[lt]", toDate);
    }

    // Add pagination
    if (pageSize) {
      params.append("page_size", pageSize.toString());
    }

    if (page) {
      params.append("page", page.toString());
    }

    // Build URL
    const url = `/v1/meter-data/${substationUID}${params.toString() ? `?${params.toString()}` : ""}`;

    logger.debug("getMeterData request", {
      url,
      substationUID,
      components,
      fromDate,
      toDate,
    });

    const fetchMeterData = await this.client.get(url);

    logger.trace("getMeterData response status", fetchMeterData.status);
    return fetchMeterData.data.data.collection;
  }
}
