import { useMemo } from "react";
import { Box } from "@mui/material";
import { DateTime } from "luxon";

import { DAY_MILIS, HOUR_MILIS, UNIT } from "@config";
import { Resolution } from "@components/EnergyConsumption/ControlPanel";
import { BaseCard, HighchartsBase } from "@shared";

import sensorFakeDataGraph from "./indoorClimateFakeData.json";

function findMinMax(values: number[]) {
  if (!Array.isArray(values) || values.length === 0) return null;
  if (!values.every(Number.isFinite)) return null;
  return {
    min: Math.min.apply(null, values),
    max: Math.max.apply(null, values),
  };
}

const filterSensorDataByDate = (
  sensorData: any[],
  dateRange?: { startDate: DateTime | null; endDate: DateTime | null }
) => {
  if (!dateRange?.startDate || !dateRange?.endDate) return sensorData;

  const startDateMillis = dateRange.startDate.toMillis();
  const endDateMillis = dateRange.endDate.toMillis();

  return sensorData.filter((item: any) => {
    const timestampMillis = DateTime.fromISO(item.timestamp).toMillis();
    return timestampMillis >= startDateMillis && timestampMillis <= endDateMillis;
  });
};

type IndoorClimateGraphProps = {
  selectedSensors: string[];
  resolution: Resolution;
  dateRange?: {
    startDate: DateTime | null;
    endDate: DateTime | null;
  };
};

const sensorFakeData = sensorFakeDataGraph.data.sensors;

const IndoorClimateGraph = ({
  selectedSensors,
  resolution,
  dateRange,
}: IndoorClimateGraphProps) => {
  const getSensorData = useMemo(() => {
    return selectedSensors.map((sensorId) => {
      const sensor = sensorFakeData.find((item) => item.uid === sensorId);
      return sensor?.sensor_readings || [];
    });
  }, [selectedSensors]);

  const filteredSensor = useMemo(() => {
    return getSensorData.map((sensorReadings) => filterSensorDataByDate(sensorReadings, dateRange));
  }, [getSensorData, dateRange]);

  const chartData = useMemo(() => {
    const seriesData = filteredSensor.map((sensorReadings, index) => {
      const sensor = sensorFakeData.find((item) => item.uid === selectedSensors[index]);
      const sensorName = sensor?.external_id || `Sensor ${index + 1}`; //default name if not found

      return {
        name: sensorName,
        data: sensorReadings.map((item) => item.living_air_temperature) || [],
      };
    });

    // Combine timestamps from all sensors (assuming they are consistent)
    const timestamps = filteredSensor[0].map((item) => DateTime.fromISO(item.timestamp).toMillis()); //take timestamps from first sensor

    return {
      timestamps,
      series: seriesData,
      minMaxTs: findMinMax(timestamps),
    };
  }, [filteredSensor, selectedSensors]);

  const plotOptions = useMemo(() => {
    return {
      series: {
        pointStart: chartData.minMaxTs?.min,
        pointInterval: resolution === "hourly" ? HOUR_MILIS : DAY_MILIS,
        pointWidth: 10,
        marker: {
          enabled: true,
        },
      },
    };
  }, [chartData.minMaxTs, resolution]);
  return (
    <BaseCard title="Average Indoor Climate">
      <Box py={2} width="100%">
        <HighchartsBase
          yUnit={UNIT.temp}
          chart={{ height: 300, type: "line" }}
          plotOptions={plotOptions}
          series={chartData.series}
          xAxis={{
            type: "datetime",
            labelFormatType: "dateOrTime",
          }}
          yAxisOptions={{
            title: {
              text: "Temperature(°C)",
            },
            resize: {
              enabled: true,
            },
          }}
          tooltip={{
            shared: true,
            split: true,
            crosshairs: true,
          }}
          exporting={{
            enabled: false,
          }}
        />
      </Box>
    </BaseCard>
  );
};

export default IndoorClimateGraph;
