/* eslint-disable @typescript-eslint/no-explicit-any */
import { queryOptions } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { create, type StateCreator } from "zustand";

import { ResourceApi } from "@apis/resource.api";
import { DEFAULT_NETWORK_TIMEZONE_NAME } from "@config";
import { logger as baseLogger, queryClient, useNetworkStore } from "@core";
import { zustandMiddlware } from "@core/stores/middleware";

const logger = baseLogger.getSubLogger({ name: "meterdata.store" });
const resourceApi = new ResourceApi();

export type MeterReading = {
  datetime: string;
  value: number;
};

export type MeterData = {
  resource_uid: string;
  meter_name: string;
  stage_name: string;
  component_name: string;
  variant_name: string;
  data: MeterReading[];
};

export type ComponentName = "heat_energy" | "returntemp" | "supplytemp";

type State = {
  isLoading: boolean;
  ready: boolean;
  meterData: MeterData[] | null;
  error: Error | string | null;
  dateRange: {
    fromDate: string | null;
    toDate: string | null;
  };
  components: ComponentName[];
  chartRefs: Record<string, any>;
};

type Action = {
  setError: (error: Error | string | null) => void;
  setReady: (ready: boolean) => void;
  setDateRange: (fromDate: string | null, toDate: string | null) => void;
  setComponents: (components: ComponentName[]) => void;
  fetchMeterData: (substationId?: string) => Promise<void>;
  clearData: () => void;
  reset: () => void;
  registerChart: (chartId: string, ref: any) => void;

  getReadingsByComponent: (componentName: ComponentName) => MeterReading[] | null;
  getLatestReadings: () => Record<string, number | null> | null;
  getFormattedDateTimeRange: () => { startFormatted: string; endFormatted: string } | null;
};

type MeterDataStore = State & Action;

const meterDataStore: StateCreator<
  MeterDataStore,
  [["zustand/devtools", never]],
  [],
  MeterDataStore
> = (set, get) => ({
  // Initial State
  isLoading: false,
  ready: false,
  meterData: null,
  error: null,
  dateRange: {
    fromDate: null,
    toDate: null,
  },
  components: ["heat_energy", "returntemp", "supplytemp"],
  chartRefs: {},

  // Actions
  setError: (error) => set({ error }, undefined, "setError"),
  setReady: (ready) => set({ ready, error: null }, undefined, "setReady"),
  setDateRange: (fromDate, toDate) =>
    set({ dateRange: { fromDate, toDate } }, undefined, "setDateRange"),
  setComponents: (components) => set({ components }, undefined, "setComponents"),

  clearData: () => set({ meterData: null }, undefined, "clearData"),

  reset: () =>
    set(
      {
        isLoading: false,
        ready: false,
        meterData: null,
        error: null,
        chartRefs: {},
      },
      undefined,
      "reset"
    ),

  registerChart: (chartId, ref) => {
    if (!chartId || !ref) {
      logger.error("registerChart failed: both `chartId` and `ref` args are required");
      return;
    }

    if (get().chartRefs[chartId]) return;

    logger.debug("Registering Meter Data Chart Reference: ", chartId);
    set(
      (state) => ({
        chartRefs: { ...state.chartRefs, [chartId]: ref },
      }),
      undefined,
      "registerChart"
    );
  },

  fetchMeterData: async (substationId) => {
    const selectedSubstationId = substationId || useNetworkStore.getState().selectedSubstationId;

    if (!selectedSubstationId) {
      logger.error("Cannot fetch meter data without a selected substation ID");
      get().setError(new Error("No substation selected"));
      return;
    }

    set({ ready: false }, undefined, "fetchMeterData_resetReady");

    const { fromDate, toDate } = get().dateRange;
    const components = get().components;

    logger.debug("fetchMeterData", {
      substationId: selectedSubstationId,
      fromDate,
      toDate,
      components,
    });

    set({ isLoading: true, error: null }, undefined, "fetchMeterData_startLoading");

    try {
      let finalFromDate = fromDate;
      const finalToDate = toDate;

      if (finalFromDate && finalToDate) {
        const fromDateTime = DateTime.fromISO(finalFromDate);
        const toDateTime = DateTime.fromISO(finalToDate);

        if (Math.abs(toDateTime.diff(fromDateTime, "hours").hours) < 1) {
          finalFromDate = toDateTime.minus({ hours: 24 }).toISO();
        }
      }

      const response = await queryClient.ensureQueryData(
        queryOptions({
          queryKey: [
            "propai_meter_data",
            selectedSubstationId,
            finalFromDate,
            finalToDate,
            components.join(","),
          ],
          queryFn: () =>
            resourceApi.getMeterData(
              selectedSubstationId,
              components,
              finalFromDate || undefined,
              finalToDate || undefined
            ),
        })
      );

      set(
        {
          meterData: response,
          isLoading: false,
          ready: true,
        },
        undefined,
        "fetchMeterData_success"
      );

      return response;
    } catch (err) {
      console.error("Error fetching meter data:", err);
      set(
        {
          error: err as Error,
          isLoading: false,
          ready: false,
        },
        undefined,
        "fetchMeterData_error"
      );
    }
  },

  getReadingsByComponent: (componentName) => {
    const { meterData } = get();
    if (!meterData) return null;

    const componentData = meterData.find((item) => item.component_name === componentName);

    return componentData?.data || null;
  },

  getLatestReadings: () => {
    const { meterData } = get();
    if (!meterData) return null;

    const result: Record<string, number | null> = {};

    for (const component of get().components) {
      const readings = get().getReadingsByComponent(component);
      if (readings && readings.length > 0) {
        // Sort by datetime descending
        const sorted = [...readings].sort(
          (a, b) => new Date(b.datetime).getTime() - new Date(a.datetime).getTime()
        );
        result[component] = sorted[0].value;
      } else {
        result[component] = null;
      }
    }

    return result;
  },

  // Get formatted date range
  getFormattedDateTimeRange: () => {
    const { dateRange } = get();
    if (!dateRange.fromDate || !dateRange.toDate) return null;

    const tzName = useNetworkStore.getState().tzName || DEFAULT_NETWORK_TIMEZONE_NAME;

    const startDate = DateTime.fromISO(dateRange.fromDate).setZone(tzName);
    const endDate = DateTime.fromISO(dateRange.toDate).setZone(tzName);

    return {
      startFormatted: startDate.toFormat("yyyy-MM-dd HH:mm"),
      endFormatted: endDate.toFormat("yyyy-MM-dd HH:mm"),
    };
  },
});

const meterDataStoreInstance = create<MeterDataStore>()(
  zustandMiddlware(meterDataStore, {
    name: "meterDataStore",
  })
);

// network store subscription to update when state changes
useNetworkStore.subscribe((state) => {
  const meterDataState = meterDataStoreInstance.getState();

  // Handle substation changes
  if (state.selectedSubstationId) {
    meterDataState.fetchMeterData(state.selectedSubstationId);
  } else {
    meterDataState.clearData();
  }

  // Handle network reset
  if (!state.ready && meterDataState.ready) {
    meterDataState.reset();
  }
});

export default meterDataStoreInstance;
