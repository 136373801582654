/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState } from "react";
import { Box, Collapse, IconButton, Paper, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCog,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";

import { useNetworkStore } from "@core";
// import meterDataStore from "@stores/meterdata.store";
import UtfDateRangePicker from "@shared/ui/inputs/UtfDateRangePicker/UtfDateRangePicker";

export type Resolution = "hourly" | "daily";

type ControlPanelProps = {
  isOpen?: boolean;
  onToggle?: (isOpen: boolean) => void;
  onDateRangeChange?: (startDate: DateTime | null, endDate: DateTime | null) => void;
  onResolutionChange?: (resolution: Resolution) => void;
  onGraphSettingsChange?: (settings: GraphSettings) => void;
  initialDateRange?: {
    startDate: DateTime | null;
    endDate: DateTime | null;
  };
  children?: React.ReactNode;
};

type GraphSettings = {
  average: boolean;
  min: boolean;
  max: boolean;
  grid: boolean;
};

const ControlPanel: React.FC<ControlPanelProps> = ({
  isOpen = true,
  onToggle,
  onDateRangeChange,
  onResolutionChange,
  onGraphSettingsChange,
  initialDateRange,
  children,
}) => {
  // const selectedSubstationId = useNetworkStore((s) => s.selectedSubstationId);

  // State variables
  const [isPanelOpen, setIsPanelOpen] = useState(isOpen);
  const [dataSettingsOpen, setDataSettingsOpen] = useState(true);
  const [graphSettingsOpen, setGraphSettingsOpen] = useState(true);
  const [timeRange, setTimeRange] = useState("lastWeek");
  const [resolution, setResolution] = useState("hourly");
  const [startDate, setStartDate] = useState<DateTime | null>(
    initialDateRange?.startDate || DateTime.now().minus({ days: 7 })
  );
  const [endDate, setEndDate] = useState<DateTime | null>(
    initialDateRange?.endDate || DateTime.now()
  );
  const [graphSettings, setGraphSettings] = useState<GraphSettings>({
    average: false,
    min: false,
    max: false,
    grid: false,
  });

  // Initialize date range if provided through props
  useEffect(() => {
    if (initialDateRange?.startDate && initialDateRange?.endDate) {
      setStartDate(initialDateRange.startDate);
      setEndDate(initialDateRange.endDate);
    }
  }, [initialDateRange]);

  // Sync with external open state
  useEffect(() => {
    setIsPanelOpen(isOpen);
  }, [isOpen]);

  const togglePanel = () => {
    const newState = !isPanelOpen;
    setIsPanelOpen(newState);
    onToggle?.(newState);
  };

  const handleTimeRangeChange = (newTimeRange: string) => {
    setTimeRange(newTimeRange);

    // Calculate date range based on selected option
    const now = DateTime.now();
    let start = null;
    let end = null;

    switch (newTimeRange) {
      case "lastDay":
        start = now.minus({ days: 1 });
        end = now;
        break;
      case "lastWeek":
        start = now.minus({ weeks: 1 });
        end = now;
        break;
      case "lastMonth":
        start = now.minus({ months: 1 });
        end = now;
        break;
    }

    setStartDate(start);
    setEndDate(end);
    onDateRangeChange?.(start, end);
  };

  const handleDateRangeChange = (start: DateTime | null, end: DateTime | null) => {
    setStartDate(start);
    setEndDate(end);
    setTimeRange("custom");
    onDateRangeChange?.(start, end);
  };

  const handleResolutionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const value = event.target.value as string;
    setResolution(value);
    onResolutionChange?.(value);
  };

  const handleGraphSettingChange =
    (setting: keyof GraphSettings) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newSettings = {
        ...graphSettings,
        [setting]: event.target.checked,
      };
      setGraphSettings(newSettings);
      onGraphSettingsChange?.(newSettings);
    };

  // Collapsed
  if (!isPanelOpen) {
    return (
      <Box
        sx={{
          position: "relative",
          width: "40px",
          height: "100%",
          borderRight: "1px solid #e0e0e0",
        }}
      >
        <IconButton
          onClick={togglePanel}
          size="small"
          sx={{
            position: "absolute",
            top: "10px",
            left: "8px",
            "&:hover": {
              bgcolor: "#f0f0f0",
            },
          }}
          aria-label="Open settings panel"
        >
          <FontAwesomeIcon icon={faChevronRight} size="sm" />
        </IconButton>
      </Box>
    );
  }

  // Expanded
  return (
    <Paper
      elevation={0}
      sx={{
        // height: "100%",
        overflow: "hidden",
        bgcolor: "#f8f8f8",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        borderRight: "1px solid #e0e0e0",
        borderRadius: 0,
      }}
    >
      {/* Header with title and close button to the right */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          p: 1.5,
          bgcolor: "#f8f4f2",
          borderBottom: "1px solid #e0e0e0",
          color: "#555",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon icon={faCog} style={{ marginRight: "8px" }} />
          <Typography sx={{ fontWeight: 500 }}>Settings</Typography>
        </Box>
        <IconButton onClick={togglePanel} size="small" aria-label="Close settings panel">
          <FontAwesomeIcon icon={faChevronLeft} size="sm" />
        </IconButton>
      </Box>

      {children}

      {/* Scrollable content area */}
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        {/* Data Settings Section */}
        <Box sx={{ height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 1.5,
              borderBottom: dataSettingsOpen ? "1px solid #e0e0e0" : "none",
            }}
            onClick={() => setDataSettingsOpen(!dataSettingsOpen)}
          >
            <Typography sx={{ fontWeight: 500 }}>Data settings</Typography>
            <FontAwesomeIcon icon={dataSettingsOpen ? faChevronUp : faChevronDown} />
          </Box>

          <Collapse in={dataSettingsOpen} sx={{ height: "100%" }}>
            <Box sx={{ p: 2, borderBottom: "1px solid #e0e0e0" }}>
              <Typography variant="caption" sx={{ display: "block", mb: 1 }}>
                Quick options
              </Typography>

              <Box sx={{ mb: 3, display: "flex" }}>
                <Box
                  onClick={() => handleTimeRangeChange("lastDay")}
                  sx={{
                    py: 0.5,
                    flex: 1,
                    cursor: "pointer",
                    border: "1px solid #ddd",
                    borderRadius: "16px 0 0 16px",
                    borderRight: "none",
                    bgcolor: timeRange === "lastDay" ? "#f0f0f0" : "#fff",
                    color: timeRange === "lastDay" ? "#f44336" : "inherit",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: "0.7rem" }}>
                    Last day
                  </Typography>
                </Box>
                <Box
                  onClick={() => handleTimeRangeChange("lastWeek")}
                  sx={{
                    py: 0.5,
                    flex: 1,
                    cursor: "pointer",
                    border: "1px solid #ddd",
                    borderRadius: "0",
                    borderRight: "none",
                    bgcolor: timeRange === "lastWeek" ? "#f0f0f0" : "#fff",
                    color: timeRange === "lastWeek" ? "#f44336" : "inherit",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: "0.7rem" }}>
                    Last week
                  </Typography>
                </Box>
                <Box
                  onClick={() => handleTimeRangeChange("lastMonth")}
                  sx={{
                    py: 0.5,
                    flex: 1,
                    cursor: "pointer",
                    border: "1px solid #ddd",
                    borderRadius: "0 16px 16px 0",
                    bgcolor: timeRange === "lastMonth" ? "#f0f0f0" : "#fff",
                    color: timeRange === "lastMonth" ? "#f44336" : "inherit",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ fontSize: "0.7rem" }}>
                    Last month
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ mb: 2 }}>
                <Typography variant="caption" sx={{ display: "block", mb: 1 }}>
                  Date selection
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <UtfDateRangePicker
                    startDate={startDate}
                    endDate={endDate}
                    onChange={handleDateRangeChange}
                    size="small"
                    data-testid="date-range-picker"
                  />
                </Box>
              </Box>

              <Box>
                <Typography variant="caption" sx={{ display: "block", mb: 1 }}>
                  Resolution
                </Typography>
                <Select
                  value={resolution}
                  onChange={handleResolutionChange}
                  fullWidth
                  size="small"
                  sx={{
                    minWidth: 120,
                    bgcolor: "#fff",
                    "& .MuiSelect-select": {
                      py: 1,
                    },
                  }}
                >
                  <MenuItem value="hourly">Hourly</MenuItem>
                  <MenuItem value="daily">Daily</MenuItem>
                </Select>
              </Box>
            </Box>
          </Collapse>
        </Box>

        {/* Graph Settings Section */}
        {/*
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 1.5,
              cursor: "pointer",
            }}
            onClick={() => setGraphSettingsOpen(!graphSettingsOpen)}
          >
            <Typography sx={{ fontWeight: 500 }}>Graph settings</Typography>
            <FontAwesomeIcon icon={graphSettingsOpen ? faChevronUp : faChevronDown} />
          </Box>

          <Collapse in={graphSettingsOpen}>
            <Box sx={{ p: 2 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Typography variant="body2">Average</Typography>
                <Checkbox
                  checked={graphSettings.average}
                  onChange={handleGraphSettingChange("average")}
                  size="small"
                  color="primary"
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Typography variant="body2">Min</Typography>
                <Checkbox
                  checked={graphSettings.min}
                  onChange={handleGraphSettingChange("min")}
                  size="small"
                  color="primary"
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <Typography variant="body2">Max</Typography>
                <Checkbox
                  checked={graphSettings.max}
                  onChange={handleGraphSettingChange("max")}
                  size="small"
                  color="primary"
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">Grid</Typography>
                <Checkbox
                  checked={graphSettings.grid}
                  onChange={handleGraphSettingChange("grid")}
                  size="small"
                  color="primary"
                />
              </Box>
            </Box>
          </Collapse>
        </Box>
        */}
      </Box>
    </Paper>
  );
};

export default ControlPanel;
