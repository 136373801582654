import { useMemo } from "react";
import { Button, Grid } from "@mui/material";
import { useQueries, useQuery } from "@tanstack/react-query";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

import { getSensorsPerSubstationOptions, getSubstationsOptions } from "@apis/resource.queries";
import { logger as baseLogger, useNetworkStore } from "@core";
import { PropertyDetail } from "@modules/PropertyDetail";
import apiStore from "@stores/api.store";
import useDashboardStore from "@stores/dashboard.store";
import { HeadingBar } from "@shared";

const logger = baseLogger.getSubLogger({ name: "DashboardModule" });

export default function DashboardModule() {
  // Server State
  const { data: substations } = useQuery(getSubstationsOptions(apiStore.get.apis()));

  const { data: substationSensors } = useQueries({
    queries: (substations || []).map((substation) =>
      getSensorsPerSubstationOptions(apiStore.get.apis(), substation.uid)
    ),
    combine: (results) => {
      const sensors = results.map((result) => {
        if (result.isSuccess) {
          return result.data;
        }
        return [];
      });
      return {
        data: sensors,
        isSuccess: results.every((result) => result.isSuccess),
      };
    },
  });

  // Client State
  const { ready } = useDashboardStore();

  const selectSubstation = (substationId: string) => {
    logger.debug("selectSubstation", { substationId });
    useNetworkStore.setState({ selectedSubstationId: substationId });
  };

  const tableData = useMemo(() => {
    const substationsWithSensors = substations?.map((substation, index) => ({
      ...substation,
      sensors: substationSensors[index]?.length,
    }));

    return substationsWithSensors || [];
  }, [substations, substationSensors]);

  const table = useMaterialReactTable({
    columns: [
      {
        header: "Name",
        accessorKey: "name",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return (
            <Button
              onClick={() => selectSubstation(cell.row.original.uid)}
              sx={{ textTransform: "none" }}
              variant="text"
              color="primary"
            >
              {value}
            </Button>
          );
        },
      },
      {
        header: "Sensors",
        accessorKey: "sensors",
      },
    ],
    data: tableData,
  });

  logger.debug("RENDER Dashboard", {
    ready,
  });

  return (
    <Grid container direction="row" spacing={2} p={2}>
      <Grid item xs={12}>
        {/* eslint-disable-next-line i18next/no-literal-string */}
        <HeadingBar title="ALL PROPERTIES" />
      </Grid>

      <Grid item xs={12}>
        <MaterialReactTable table={table} />
      </Grid>

      {/* Property detail overlay */}
      <PropertyDetail />
    </Grid>
  );
}
