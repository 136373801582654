import { useEffect, useMemo, useState } from "react";
import { Grid } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import { DateTime } from "luxon";

import { UNIT } from "@config";
import { useNetworkStore } from "@core";
import meterDataStore from "@stores/meterdata.store";
import { BaseCard, FilterBar, HeadingBar, HighchartsBase } from "@shared";

// Import components for MeterChart and ControlPanel
import { ControlPanel, MeterChart } from "../../components/EnergyConsumption";

// Generate sample data
const generateData = (count: number, base: number, variance: number) => {
  return Array.from({ length: count }, (_, i) => {
    const date = new Date(2024, 5, 14, 0, 0, 0, 0);
    date.setHours(date.getHours() + i);
    return [date.getTime(), Math.max(0, base + Math.random() * variance - variance / 2)];
  });
};

const normalData = generateData(120, 4, 6);
const optimizedData = generateData(120, 3.8, 5.8);

function HeatEnergyGraph() {
  const theme = useTheme();

  return (
    <BaseCard title="HEAT ENERGY METER DATA">
      <HighchartsBase
        disableExporting={true}
        rangeSelector={{
          enabled: false,
        }}
        navigator={{
          enabled: false,
        }}
        {...{
          xAxis: {
            type: "datetime",
            tickInterval: 24 * 3600 * 1000,
            labels: {
              format: "{value:%d %b}",
            },
          },
          yAxis: {
            title: {
              text: "Heat Energy kW/h",
            },
            min: 0,
            max: 10,
          },
          series: [
            {
              name: "Heat energy (normal)",
              data: normalData,
              color: theme.palette.primary.light,
            },
            {
              name: "Heat energy (optimized)",
              data: optimizedData,
              color: theme.palette.secondary.light,
            },
          ],
          legend: {
            enabled: true,
          },
          tooltip: {
            shared: true,
            crosshairs: true,
            xDateFormat: "%d %b %H:%M",
          },
        }}
      />
    </BaseCard>
  );
}

function OutdoorGraph() {
  const theme = useTheme();
  return (
    <HighchartsBase
      {...{
        chart: {
          type: "scatter",
          zoomType: "xy",
        },
        title: {
          text: "Heat Energy vs Outdoor Temperature",
        },
        xAxis: {
          title: {
            enabled: true,
            text: "Outdoor Temperature (°C)",
          },
          startOnTick: true,
          endOnTick: true,
          showLastLabel: true,
        },
        yAxis: {
          title: {
            text: "Heat Energy (kWh/h)",
          },
        },
        series: [
          {
            name: "Heat Energy Data",
            color: theme.palette.secondary.main,
            data: [
              [8.2, 3.8],
              [8.3, 3.7],
              [8.5, 4.1],
              [8.7, 3.9],
              [8.8, 4.7],
              [8.9, 4.7],
              [9.1, 3.8],
              [9.2, 4.1],
              [9.3, 3.2],
              [9.4, 4.7],
              [9.5, 4.3],
              [9.6, 3.8],
              [9.7, 5.8],
              [9.8, 3.8],
              [9.9, 4.7],
              [10.0, 4.8],
              [10.1, 5.8],
              [10.2, 3.8],
              [10.3, 6.8],
              [10.4, 5.8],
              [10.5, 4.6],
              [10.6, 3.8],
              [10.7, 4.7],
              [10.8, 1.8],
              [10.9, 4.7],
              [11.0, 5.8],
              [11.1, 4.8],
              [11.2, 3.7],
              [11.3, 2.8],
              [11.4, 4.8],
              [11.5, 5.7],
              [11.6, 4.8],
              [11.7, 7.7],
              [11.8, 8.7],
              [11.9, 5.8],
              [12.0, 4.8],
              [12.1, 9.7],
              [12.2, 5.8],
              [12.3, 0.8],
              [12.4, 3.7],
              [12.5, 4.8],
              [12.6, 5.7],
              [12.7, 6.8],
              [12.8, 3.7],
              [12.9, 4.8],
              [13.0, 5.8],
              [13.1, 2.8],
              [13.2, 3.7],
              [13.3, 0.8],
              [13.4, 3.8],
              [13.5, 5.7],
              [13.6, 9.7],
              [13.7, 2.8],
              [13.8, 8.7],
              [13.9, 5.8],
              [14.0, 3.7],
              [14.1, 2.8],
              [14.2, 1.7],
              [14.3, 3.8],
              [14.4, 5.7],
              [14.5, 2.8],
              [14.6, 3.7],
              [14.7, 1.8],
              [14.8, 5.7],
              [14.9, 3.8],
              [15.0, 7.7],
              [15.1, 1.8],
              [15.2, 2.7],
              [15.3, 4.8],
              [15.4, 3.7],
              [15.5, 0.8],
              [15.6, 2.7],
              [15.7, 4.8],
              [15.8, 1.7],
              [15.9, 0.8],
              [16.0, 3.7],
              [16.1, 4.8],
              [16.2, 8.7],
              [16.3, 0.7],
              [16.4, 0.8],
              [16.5, 2.7],
              [16.6, 4.8],
              [16.7, 3.7],
              [16.8, 1.8],
              [16.9, 0.7],
              [17.0, 3.8],
              [17.1, 0.7],
              [17.2, 1.8],
            ],
          },
        ],
      }}
      legend={{ enabled: false }}
      yUnit={UNIT.energy}
    />
  );
}

export function PropertyDetailEnergyData() {
  const theme = useTheme();
  const fetchedRange = useNetworkStore((s) => s.fetchedRange);
  const registerChart = useNetworkStore((s) => s.registerChart);
  const selectedSubstationId = useNetworkStore((s) => s.selectedSubstationId);
  const networkLoading = useNetworkStore((s) => !s.ready);

  // meter data store
  const meterDataLoading = meterDataStore((s) => s.isLoading);
  const meterDataError = meterDataStore((s) => s.error);
  const meterDataReady = meterDataStore((s) => s.ready);
  const fetchMeterData = meterDataStore((s) => s.fetchMeterData);
  const setMeterDataDateRange = meterDataStore((s) => s.setDateRange);
  const rawMeterData = meterDataStore((s) => s.meterData);

  const isInitialLoading = networkLoading || (!rawMeterData && meterDataLoading);

  // State for ControlPanel
  const [isPanelOpen, setIsPanelOpen] = useState(true);
  const [dateRange, setDateRange] = useState({
    startDate: DateTime.now().minus({ days: 7 }),
    endDate: DateTime.now(),
  });
  const [resolution, setResolution] = useState("hourly");
  const [graphSettings, setGraphSettings] = useState({
    average: false,
    min: false,
    max: false,
    grid: false,
  });

  const formattedMeterData = useMemo(() => {
    if (!rawMeterData || rawMeterData.length === 0) {
      console.log("No meter data available");
      return [];
    }

    return rawMeterData.map((component) => {
      const sortedData = [...component.data].sort(
        (a, b) => new Date(a.datetime).getTime() - new Date(b.datetime).getTime()
      );

      return {
        uid: component.resource_uid,
        meter: component.meter_name,
        stage: component.stage_name,
        component: component.component_name,
        variant: component.variant_name,
        data: sortedData.map((point) => ({
          datetime: point.datetime,
          value: point.value,
          flags: [],
        })),
      };
    });
  }, [rawMeterData]);

  // Get meter data
  useEffect(() => {
    if (selectedSubstationId && dateRange.startDate && dateRange.endDate) {
      // Update date range in store
      setMeterDataDateRange(dateRange.startDate.toISO(), dateRange.endDate.toISO());

      // Fetch data
      fetchMeterData(selectedSubstationId);
    }
  }, [selectedSubstationId, dateRange.startDate, dateRange.endDate]);

  // Handle control panel changes
  const handlePanelToggle = (isOpen: boolean) => {
    setIsPanelOpen(isOpen);
  };

  const handleDateRangeChange = (startDate: DateTime | null, endDate: DateTime | null) => {
    if (startDate && endDate) {
      setDateRange({ startDate, endDate });
    }
  };

  const handleResolutionChange = (newResolution: string) => {
    setResolution(newResolution);
  };

  const handleGraphSettingsChange = (settings: typeof graphSettings) => {
    setGraphSettings(settings);
  };

  return (
    <Box width="100%">
      <Box sx={{ display: "flex", width: "100%" }}>
        {/* Control Panel Sidebar */}
        <Box
          sx={{
            position: "sticky",
            top: 0,
            height: "100vh",
            flexShrink: 0,
            zIndex: 10,
            alignSelf: "flex-start",
            bgcolor: "background.paper",
          }}
        >
          <ControlPanel
            isOpen={isPanelOpen}
            onToggle={handlePanelToggle}
            onDateRangeChange={handleDateRangeChange}
            onResolutionChange={handleResolutionChange}
            onGraphSettingsChange={handleGraphSettingsChange}
            initialDateRange={dateRange}
          />
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            overflow: "auto",
            transition: "padding 0.3s ease",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} mb={2}>
              <MeterChart
                data={formattedMeterData}
                isLoading={isInitialLoading}
                dateRange={dateRange}
                resolution={resolution}
                graphSettings={graphSettings}
              />
            </Grid>
            <Grid item xs={12} mb={2}>
              <HeatEnergyGraph />
            </Grid>
            <Grid item xs={12} mb={2}>
              <OutdoorGraph />
            </Grid>
          </Grid>
          <FilterBar data={normalData} range={fetchedRange} registerChart={registerChart} />
        </Box>
      </Box>
    </Box>
  );
}
