import { useEffect, useMemo } from "react";
import {
  Alert,
  AlertTitle,
  AppBar,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { faGlobe, faNetworkWired, faRightFromBracket } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";

import { getNetworksOptions } from "@apis/resource.queries";
import { logger, useNetworkStore } from "@core";
import apiStore from "@stores/api.store";
import { useLocalStorage } from "@hooks";
import { LoaderScreen } from "@shared/LoaderScreen";

const pageTitle = "PropAI";

const AppLayout = () => {
  const theme = useTheme();
  const {
    data: allNetworks,
    isSuccess: networksLoaded,
    error: networkError,
  } = useQuery(getNetworksOptions(apiStore.get.apis()));

  const selectedNetwork = useNetworkStore((state) => state.selectedNetworkId);
  const [defaultNetwork, setDefaultNetwork] = useLocalStorage("defaultNetwork", "");
  const { auth } = Route.useRouteContext();

  // Once networks are loaded, select the first network or the default network
  useEffect(() => {
    if (!networksLoaded) return;

    if (selectedNetwork) return;
    if (allNetworks?.length) {
      const network = allNetworks.find(({ uid }) => uid === defaultNetwork);
      if (network) {
        useNetworkStore.setState({ selectedNetworkId: network.uid });
      }
      // If default network is not found, select the first network
      else {
        useNetworkStore.setState({ selectedNetworkId: allNetworks[0].uid });
      }
    }
  }, [defaultNetwork, networksLoaded, allNetworks, selectedNetwork]);

  const selectNetwork = (networkId: string) => {
    const network = allNetworks?.find(({ uid }) => uid === networkId);
    if (network) {
      useNetworkStore.setState({ selectedNetworkId: network.uid });
      setDefaultNetwork(network.uid);
    }
  };

  const outletRenderer = networksLoaded ? <Outlet /> : <LoaderScreen />;

  logger.debug("RENDER <AppLayout> %j", { networksLoaded, selectedNetwork });

  return (
    <>
      <AppBar
        elevation={0}
        position="static"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: "primary.dark",
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          color: "white",
          height: "5.14rem",
          py: 1,
        }}
      >
        <Toolbar>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item flex={1}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <img src="/hafslund.webp" alt="Hafslund | PropAI" height="32px" />
                <Typography
                  variant="h4"
                  data-testid="page-title"
                  fontSize={32}
                  fontWeight={400}
                  fontFamily="Roboto"
                  ml={2}
                >
                  {pageTitle}
                </Typography>
              </Box>
            </Grid>
            {/* Network Selection & Logout */}
            <Grid item>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <FontAwesomeIcon icon={faNetworkWired} />
                {!networksLoaded ? (
                  <Typography variant="body1" p={2} sx={{ color: "white" }}>
                    Loading Networks...
                  </Typography>
                ) : (
                  <Box display="flex" alignItems="center">
                    {/* Networks Dropdown */}
                    <Select
                      labelId="network-select-label"
                      value={selectedNetwork?.uid || ""}
                      onChange={(e) => selectNetwork(String(e.target.value))}
                      sx={{ color: "white", svg: { fill: "white" } }}
                    >
                      {allNetworks?.map(({ uid, name }) => (
                        <MenuItem key={uid} value={uid}>
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                )}
              </Box>
            </Grid>
            {/* Logout */}
            <Grid item>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Tooltip title="Logout">
                  <IconButton size="small" onClick={() => auth?.logout()}>
                    <FontAwesomeIcon color="white" icon={faRightFromBracket} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {/* Main/Route Content */}
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          height: "100vh",
          color: "grey.800",
        }}
      >
        {/* Body */}
        <Box
          component="section"
          height="100%"
          overflow="auto"
          flexGrow={1}
          display="flex"
          flexDirection="column"
        >
          {networkError ? (
            <Box p={4}>
              <Alert severity="error">
                <AlertTitle>{networkError.message}</AlertTitle>
                This likely means you dont have access to any network. Please contact your admin.
              </Alert>
            </Box>
          ) : (
            outletRenderer
          )}
        </Box>
      </Box>
    </>
  );
};

export const Route = createFileRoute("/app/_layout")({
  component: AppLayout,
  // Before loading, authenticate the user via our auth context
  // This will also happen during prefetching (e.g. hovering over links, etc)
  beforeLoad: ({ context, location }) => {
    // If the user is logged out, redirect them to the login page
    if (!context.auth?.isAuthenticated && !context.auth?.isLoading) {
      throw redirect({
        to: "/login",
        search: {
          redirect: location.href,
        },
      });
    }
  },
});
