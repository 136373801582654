/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { queryOptions } from "@tanstack/react-query";

import { AvialableApis } from "@stores/api.store";

export const getSubstationsOptions = (apis: AvialableApis) =>
  queryOptions({
    queryKey: ["substations"],
    queryFn: () => apis.resourceApi.getSubstations(),
  });

export const getNetworksOptions = (apis: AvialableApis) =>
  queryOptions({
    queryKey: ["networks"],
    queryFn: () => apis.resourceApi.getNetworks(),
  });

export const getSensorsPerSubstationOptions = (apis: AvialableApis, substationUid?: string) =>
  queryOptions({
    queryKey: ["sensors", substationUid],
    queryFn: () => apis.resourceApi.getSensorsBySubstation(substationUid),
    enabled: !!substationUid,
  });
